#contact
{
    text-align: center;
    padding: 60px 20px;
    background-color: #FFE9C2;
}

#contact p:nth-child(1)
{
    font-family: nexity-medium;
    font-size: 24px;
}

#contact p:nth-child(2)
{
    margin: 10px 0 30px 0 ; 
    font-family: nexity-regular;
    font-size: 16px;
}

#contact button
{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin: 20px auto ;
    width: 200px;
    height: 50px;
    background: #D22238;
    border-radius: 3px;
    font-family: nexity-bold;
    font-size: 18px;
}
#contact button img
{
    margin-left: 10px;
}