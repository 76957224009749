@import '../scss/functions.scss';

#container_simulateur
{
    padding: 60px 19px 0 19px;
    scroll-margin-top: 150px;
}

.simulateur_header
{
    background: linear-gradient(180deg, #FFE9C2 0%, #FFE9C2 100%);
    padding: 32px 28px 30px 20px;
}
.simulateur_header div
{
    width: 300px;
    margin: 0 auto;
}
.simulateur_header p 
{
    max-width: 290px;
    font-family: nexity-regular;
    font-size: 16px;
    margin: 10px auto;
}
.simulateur_header div h1
{
    padding: 10px;
    display: inline-block;
    color: white;
    background: rgba(255, 186, 76, 0.95);
    font-family: nexity-bold;
    font-size: 42px;
}
.simulateur_action
{
    text-align: center;
    background: linear-gradient(180deg, #FFBA4C 0%, #FFE9C2 100%);
    padding: 60px 20px;
}
.simulateur_action div p
{
    margin-bottom: 10px;
    font-family: nexity-medium;
    font-size: 24px;
    color: white;
}
.simulateur_action > div > div
{
    position: relative;
    width: 300px;
    height: 100px;
    padding: 10px 20px 20px 20px;
    margin: 0 auto;
    text-align: initial;
    background-color: white;
}
.simulateur_action div div img
{
    position: absolute;
    right: 28px;
    bottom: 30px;
}
.simulateur_action div div label
{
    font-family: nexity-bold;
    font-size: 18px;
    margin-bottom: 10px;
}
.simulateur_action div input
{
    width: 255px;
    font-family: nexity-regular;
    font-size: 16px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #2B2B2B;
}
.simulateur_action div input::placeholder
{
    font-family: nexity-regular;
    font-size: 16px;
}
.cta_simulateur
{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin: 20px auto ;
    border: none;
    width: 200px;
    height: 50px;
    background: #D22238;
    border-radius: 3px;
    font-family: nexity-bold;
    font-size: 18px;
}
.btn_simulation
{
   display: flex;
   justify-content: center;
   align-items: center;
   margin: 0 auto 0 auto;
   padding: 0;
   font-family: nexity-bold;
   font-size: 18px;
   border: none;
   background-color: transparent;
   text-decoration: underline; 
}
.btn_simulation img
{
    margin-right: 10px;
}
#error_compa
{
    margin-top: 5px;
    color:red;
    font-family: nexity-regular;
    font-size: 12px;
}
.pinel_style
{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: #D22238 !important;
    height: 46px ;
    margin-left: 5px;
    transform: rotate(-1deg);
    width: fit-content;
}

.simulateur_start
{
    color: white;
    padding: 60px 20px;
    background: linear-gradient(180deg, #FFBA4C 0%, #FFE9C2 100%);
    border-radius: 3px;
    animation: fade ease 300ms;
}
.budget_start
{
    position: relative;
    width: vwResp(300);
    height: 90px;
    margin: 0 auto;
    padding: 10px 20px 20px 20px;
    text-align: initial;
    background-color: white;
}
.budget_start img
{
    position: absolute;
    right: 25px;
    bottom: 24px;
}
.budget_start label
{
    color: black;
    font-family: nexity-bold;
    font-size: 18px;
    margin-bottom: 10px;
}
.budget_start input
{
    width: vwResp(255);
    font-family: nexity-regular;
    font-size: 16px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #2B2B2B;
}
.calcul_field
{
    margin-bottom: 20px;
}
.calcul_field > p
{
    margin-bottom: 10px;
    font-family: nexity-regular;
    font-size: 16px;
}
.calcul_field p strong
{
    font-size: 24px;
}
.defiscal
{
    display: flex;
    align-items: center;
    justify-content: center;
    width: vwResp(300);
    height: 90px;
    margin: 0 auto;
    color: black;
    background-color: white;
}
.defiscal p
{
    font-family: nexity-bold;
    font-size: 38px;
}
.total_economy
{
    display: flex;
    align-items: center;
    justify-content: center;
    width: vwResp(300);
    height: 90px;
    margin: 0 auto;
    background: #FFBC52;
}
.total_economy-label {
    font-family: nexity-bold !important;
    font-size: 20px !important;
}
.total_economy p
{
    font-family: nexity-bold;
    font-size: 38px;
}
.btn_retry
{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 0 auto;
    padding: 0;
    font-family: nexity-bold;
    font-size: 18px;
    border: none;
    background-color: transparent;
    text-decoration: underline; 
}
.btn_retry img
{
    margin-right: 10px;
}
.cta_simulateur-start
{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin: 20px auto ;
    border: none;
    width: 200px;
    height: 50px;
    background: #D22238;
    border-radius: 3px;
    font-family: nexity-bold;
    font-size: 18px;
}


@media screen and (min-width: 1024px)
{
    #container_simulateur
    {
        display: flex;
        justify-content: center;
        margin-bottom: 50px;
    }
    .simulateur_home
    {
        display: flex;
        justify-content: center;
    }
    .simulateur_header
    {
        width: 48vw;
        padding: 91px 67px;
    }
    .simulateur_header p
    {
        max-width: 350px;
        margin: 10px 0;
    } 
    .simulateur_header div
    {
        display: flex;
        align-items: center;
        width: 412px;
        margin:0;
        h1 {
            font-size: vwDeskt(42);
        }
    }
    .total_economy {
        width: 300px;
    }
    .calcul_field p {
        margin-right: 10px;
    }
    .simulateur_action
    {
        display: flex;
        align-items: center;
        padding: 30px 0px 30px 111px;
        width: 48vw;
    }
    .budget_start {
        width:300px;
    }
    .budget_start input {
        width: vwDeskt(255);
    }
    .simulateur_header-pinel
    {
        margin-left: -12px;
    }
    .pinel_style
    {
        width: max-content;
        height: vwDeskt(46);
        padding: 5px;
    }   
    .btn_simulation
    {
        margin: 0 ;
    }
    .simulateur_action div p
    {
        text-align: initial;
    }
    .cta_simulateur
    {
        width: vwDeskt(124);
    }
    .defiscal {
        width: 300px;
    }
    .container_fields
    {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
    .calcul_field p strong
    {
        font-family: nexity-regular;
        font-size: 16px;
    }
}
@media screen and (max-width: 365px)
{
    .simulateur_header div h1
    {
        font-size: vwResp(36);
    }
    .simulateur_header div
    {
        width: initial;
    }
    .simulateur_action div div
    {
        width: vwResp(300);
    }
    .simulateur_action div input 
    {
        width: 200px;
    }
}
