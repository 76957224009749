.legend
{
    padding: 0px 50px;
    position: absolute;
    right: 0;
    width: 30vw;
    height: 600px;
    background-color: white;
    z-index: 2345;
}
.legend_prices
{
    display: flex;
    align-items: center;
}
.legend_prices img
{
    margin-bottom: 5px;
    margin-right: 10px;
    width: 30px;
    height: 30px;
}
.legend_prices p 
{
    font-family: nexity-regular;
}
#legend_description
{
    display: none;
    text-align: center;
}
.legend_loyer > p:nth-child(3) 
{
    font-family: nexity-bold;
    margin-bottom: 10px;
    margin-top: 20px;
    font-size: 12px;
}
.legend_loyer-header
{
    font-family: nexity-bold;
    font-size: 15px;
    margin-top: 20px;
}
.box_light
{
    margin-right: 10px;
    width: 30px;
    height: 30px;
    background: #FFE9C2;
    margin-bottom: 5px;
}
.box_medium
{
    margin-right: 10px;
    width: 30px;
    height: 30px;
    background: #FFBC52;
    margin-bottom: 5px;
}
.box_high
{
    margin-right: 10px;
    width: 30px;
    height: 30px;
    background: #D22238;
    margin-bottom: 5px;
}

.cta_map
{
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    color: white;
    width: 200px;
    height: 25px;
    background: #D22238;
    font-family: nexity-bold;
    font-size: 14px;
}
.cta_map img
{
    margin-left: 10px;
}
#price_map
{
    margin-bottom: 10px;
    font-family: nexity-medium;
    font-size: 20px;
}
#title_map
{
    font-family: nexity-bold;
    font-size: 20px;
}
.leaflet-control-zoom-in,.leaflet-control-zoom-out
{
    display:none;
}
.btn_reset
{
    display: flex;
    position: absolute;
    z-index: 2346;
    top: 20px;
    left: 50%;
    margin: 0px auto 10px auto;
    justify-content: center;
    align-items: center;
    color: black;
    width: 200px;
    height: 40px;
    background: white;
    font-family: nexity-bold;
    font-size: 18px;
    cursor: pointer;
    transition: all 400ms;
}
.btn_reset:hover
{
    color: #D22238;
}
.data_source
{
    font-family: nexity-regular;
    font-size: 12px !important;
    margin-top: 5px;
}
@media screen and (max-width: 1024px)
{
    .legend
    {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 15px;
        right: initial;
        bottom: 0;
        width: 100vw;
        height: 220px;
    }
    .legend_prices img 
    {
        width: 20px;
        height: 20px;
    }
    .box_light
    {
        width: 20px;
        height: 20px;
    }
    .box_medium
    {
        width: 20px;
        height: 20px;
    }
    .box_high
    {
        width: 20px;
        height: 20px;
    }
    .cta_map
    {
        width: 130px;
        height: 40PX;
        font-size: 13px;
    }
    #price_map
    {
        font-size: 10px;
    }
    #title_map
    {
        font-size: 15px;
    }
    .btn_reset
    {
        width: 100px;
        height: 20px;
        font-size: 12px;
        left: 70%;
    }
    .legend_loyer > p 
    {
        margin-bottom: 0;
        font-size: 10px;
    }
    .legend_prices p
    {
        font-size: 10px;
    }
    #legend_description
    {
        margin-top: 0;
    }
    .data_source
    {
        font-size: 8px !important;
    }
}