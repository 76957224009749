ul,h1,h2,p,div
{
  margin: 0 ;
  padding: 0 ;
}
a
{
  text-decoration: none;
  color: rgb(0, 0, 0);
  transition: all 250ms;
}
sup
{
  font-size: 0.60em;
  top: -0.3em;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
body {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll; 
}

body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: 'nexity-medium';
  src: local('nexity-medium'), url(./fonts/Nexity-Medium.otf) format('truetype');
}
@font-face {
  font-family: 'nexity-bold';
  src: local('nexity-bold'), url(./fonts/Nexity-Bold.otf) format('truetype');
}
@font-face {
  font-family: 'nexity-regular';
  src: local('nexity-regular'), url(./fonts/Nexity-Regular.otf) format('truetype');
}


/* ANIMATION BTN */

/* CTA ANIMATION */

button.grow_spin::after {
    content: '';
    position: absolute;
    transform: scaleX(0);
    width: 100%;
    height: 100%;
    background-color: 
    #FFBC52;
    transition: all 0.3s;
    transform-origin: center left;
    z-index: -1;
}
button.grow_spin:hover::after {
  transform: scaleX(1);
}

button.grow_spin
{
  position: relative;
  z-index: 1;
  overflow: hidden;
  border: none;
}
button.grow_spin:hover
{
    color: white;
    border: none
}


/* Anim ancre */

a:hover
{
  color: #D22238;
}

/* Fix bug z-index data-aos */
.aos-init[data-aos][data-aos].aos-animate {
  transform: unset;
}
