#container_video
{
    margin-top: 100px;
    z-index: 10000;
}
.video_respect
{
    font-family: nexity-regular;
    font-size: 14px;
    margin: 0 auto 12px auto;
    max-width: 300px;
}
.video_player
{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('../images/video_bg.png') ;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover; 
    margin: 0 auto;
    height: 38vh;
    width: 90%;
    cursor: pointer;
}
.video_description
{
    text-align: center;
    width: 300px;
    margin: 0 auto;
}

.video_description h1
{
    font-size: 42px;
    line-height: 58.8px;
    font-family: nexity-bold;
}
.video_description h2
{
    margin: 14px 0;
    font-family: nexity-medium;
    font-size: 24px;
    line-height: 34px;
}
.video_description p 
{
    font-family: nexity-regular;
    font-size: 24px;
    line-height: 34px;
}
.cta_video
{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto 12px auto;
    padding: 0;
    font-family: nexity-bold;
    font-size: 24px;
    border: none;
    background-color: transparent;
    text-decoration: underline; 
}
.cta_video img
{
    margin-right: 10px;
}

.video_modal
{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 10000;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.9);
}
.closevideo
{
    color: white;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 40px;
}
.overlay
{
    text-align: center;
    color: white;
    padding: 0 10px;
}

.overlay p 
{
    font-family: nexity-medium;
    font-size: 14px;
}
.cta_overlay
{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin: 20px auto ;
    width: 150px;
    height: 40px;
    background: #D22238;
    border-radius: 3px;
    font-family: nexity-bold;
    font-size: 18px;
}
@media screen and (min-width: 1024px)
{
    #container_video
    {
        display: flex;
        flex-direction: row-reverse;
        margin-top: 20px ;
    }
    #container_video > div  
    {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto 0 auto;
    }
    .video_player
    {
        margin: 0 0 0 auto;
        width: 677px;
        height: 381px;
    }
    .video_respect {
        max-width: initial;
        margin-top: 5px;
    }
    .video_description {
        text-align: initial;
    }
    .logo_video
    {
        transition: all 500ms;
        opacity: 0.5;
    }
    .video_player:hover > .logo_video
    {
        opacity: 1;
    }
    .overlay p
    {
        font-size: 20px;
    } 
    .cta_overlay
    {
        width: 200px;
        height: 50px;
    }
}

