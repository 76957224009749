#trustpilot
{
    padding: 60px 20px 60px 28px;
}
.trustpilot_nexity
{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 20px auto;
    max-width: 375px;
}
.trustpilot_nexity img
{
    margin-right: 20px;
}
.trustpilot_nexity div h1
{
    font-family: nexity-medium;
    font-size: 24px;
}
.trustpilot_nexity div p
{
    font-family: nexity-regular;
    font-size: 16px;
    margin-top: 10px;
}
.trustpilot_nexity div a
{
    font-family: nexity-bold;
    font-size: 16px;
}

.trustpilot_rating
{
    max-width: 375px;
    margin: 0 auto;
}

.trustpilot_rating > img
{
    margin-bottom: 30px;
}

.trustpilot_rating div
{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.trustpilot_rating div img:nth-child(1)
{
    margin-right: 30px;
}

.trustpilot_rating div p
{
    font-family: nexity-bold;
    font-size: 24px;
    margin-right: 10px;
}

@media screen and (min-width: 1024px)
{
    #trustpilot
    {
        display: flex;
        justify-content: center;
        padding: 60px 0px 40px 0px;
    }
    .trustpilot_nexity
    {
        max-width: initial;
        margin: 0 150px 0 0;
        
    }
    .trustpilot_nexity img
    {
        margin-right: 20px;
    }
    .trustpilot_rating
    {
        margin: 0;
    }
}