#nexity_stat {
  padding: 60px 30px;
  text-align: center;
  background-color: #ffe9c2;
  background: url("../images/stats_bg-deskt.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

#nexity_stat h1 {
    color: white;
  font-family: nexity-bold;
  font-size: 42px;
}
#nexity_stat > p {
  color: white;
  font-family: nexity-medium;
  font-size: 24px;
  margin: 10px 0 30px 0;
}

.nexity_stat-card {
  width: 205px;
  margin: 0 auto;
  background-color: #ffbc52;
  margin-bottom: 1px;
  padding: 30px 35px;
}
.nexity_stat-card p {
  font-family: nexity-regular;
  font-size: 16px;
}
.stat-style {
  font-family: nexity-bold;
  font-size: 42px;
  color: white;
  background: #d22238 !important;
  padding: 5px;
  height: 46px !important;
  transform: rotate(-1deg);
  margin-bottom: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
}
.stat_asterix {
  font-family: nexity-regular !important;
  font-size: 12px !important;
}

@media screen and (min-width: 1024px) {
  #nexity_stat {
    background: url("../images/stats_bg-deskt.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .nexity_stat_cards {
    display: flex;
    justify-content: center;
  }
  .nexity_stat-card {
    margin: 0 15px;
  }
}
