footer
{
    height: 80px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #111111;
}
footer a
{
    color: white;
    font-family: nexity-medium;
    font-size: 10px;
}
footer a:hover
{
    color: white;
}
footer span
{
    margin: 0 10px
}

@media screen and (max-width: 1024px)
{
    footer 
    {
        flex-direction: column;
    }
    footer span
    {
        display: none;
    }
}