@import "../scss/functions.scss";

#boost_graph {
  margin: 0 auto;
  max-width: 340px;
  padding: 30px 23px;
  scroll-margin-top: 150px;
  > img {
    margin-top: 45px;
  }
  div {
    h2 {
      font-family: nexity-bold;
      font-size: 42px;
      line-height: 46px;
      color: #2b2b2b;
    }
    p {
      margin: 20px 0;
      font-family: nexity-regular;
      font-size: 16px;
      line-height: 22px;
      color: #2b2b2b;
    }
  }
}
.graph_description {
  margin: 0 !important;
}
.graph_container {
  margin-top: 40px;
  max-width: 350px;
  height: 200px;
  background-image: url("../images/graph.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
.cta_graph {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin: 10px 0;
  width: 200px;
  height: 50px;
  background: #d22238;
  border-radius: 3px;
  font-family: nexity-bold;
  font-size: 18px;
  img {
    width: 10px;
    height: 10px;
    margin-left: 13px;
  }
}

@media screen and (min-width: 1024px) {
  #boost_graph {
    max-width: initial;
    padding: 50px 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      max-width: 500px;
      height: 260px;
      margin-right: 20px;
      &.graph {
        max-width: min-content;
      }
      &.graph_container {
        max-width: initial;
        margin-top: 0;
        margin-right: 0px;
        min-width: 386px;
        width: 386px;
        background-image: url("../images/graph-deskt.png");
      }
    }
  }
  .graph_description {
    margin: -25px 0 0 0 !important;
  }
}
