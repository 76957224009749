.header_fixed
{
    position: fixed;
    width: 100vw;
    z-index: 9999;
    animation: fade ease 1s;
    top: 0;
}
.header_none
{
    display: none;
    top: 0;
}
@keyframes fade {
    from {opacity: 0;transform: translateY(-150px);}
    to {opacity: 1;transform:translateY(0px) ;}
}