#form
{
    position: fixed;
    width: 100vw;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 20px 20px;
    background: #FFBC52;
    color: white;
    z-index: 100000;
    overflow-y: scroll;
    animation: fade ease 300ms;
}

.form_part1 input
{
    width: 80vw;
    min-height: 30px;
    margin-right: 90px;
    font-family: nexity-bold;
    font-size: 17px;
    color: black;
}
.form_part1 > div
{
    margin-bottom: 25px;
}
.form_part1 input::placeholder
{
    padding-top: 10px;
    font-family: nexity-bold;
    font-size: 17px;
    color: #FFBC52;
}
.form_header
{
    width: 90vw;
}
.form_header h1
{
    font-family: nexity-medium;
    font-size: 15px;
    margin-bottom: 10px;
}

.form_header p
{
    font-family: nexity-medium;
    font-size: 12px;
    margin-bottom: 30px;
}
fieldset legend
{
    margin-bottom: 25px;
    font-family: nexity-medium;
    font-size: 12px;
}
fieldset div
{
    display: flex;
    align-items: center;
    padding-left: 40px;
}
fieldset div label
{
    margin-left: 10px;
    font-family: nexity-medium;
    font-size: 12px;
}
input[type="radio"]
{
    width: 12px;
    height: 12px;
}
#offre_nexity2,#offre_partenaire2
{
    margin-left: 81px;
}
.form_part2 > div
{
    margin-bottom: 25px;
}
.form_part2 > p
{
    font-family: nexity-medium;
    font-size: 17px;
}
.coord_container
{
    display: flex;
}
.coord_container input
{
    width: 30px;
    height: 30px;
    margin-right: 20px;
}
.submit_btn
{
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-family: nexity-bold;
    font-size: 15px;
    color: white;
    width: 150px;
    height: 40px;
    background: #CE162B;
}
.submit_btn img
{
    margin-left: 10px;
    width: 15px;
    height: 15px;
}
.close_form
{
    cursor: pointer;
    font-family: nexity-bold;
    font-size: 20px;
    position: fixed;
    color: black;
    top: 30px;
    right: 30px;
}

.error_style
{
    display: none;
    color: red;
    font-family: nexity-regular;
    font-size: 10px;
}
.greetings
{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100vw;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 20px 20px;
    background: #FFBC52;
    color: white;
    z-index: 100000;
    overflow-y: scroll;
    animation: fade ease 300ms;
}
@media screen and (min-width: 1024px)
{
    #form
    {
        padding: 50px 30px 50px 100px;
    }
    #form div form > div
    {
        display: flex;
        justify-content: space-around;
    }
    .form_header {
        width: 55vw;
    }
    .form_header p 
    {
        margin-bottom: 60px;
    }
    .form_header h1 {
        font-family: nexity-medium;
        font-size: 25px;
        margin-bottom: 10px;
    }    
    .form_part1 input
    {
        width: 45vw;
    }
    .close_form {
        color: black;
        cursor: pointer;
        font-family: nexity-bold;
        font-size: 20px;
        position: fixed;
        top: 50px;
        right: 50px;
    }
    .submit_btn
    {
        font-size: 15px;
        width: 143px;
        height: 28px;
    }
    .submit_btn img
    {
        margin-left: 10px;
        width: 15px;
        height: 15px;
    }
    .error_style
    {
        font-size: 16px;
    }
}