@import '../scss/functions.scss';

#container_hero_banner {
  height: 436px;
  width: 100%;
  padding: 20px 20px 20px 20px;
  position: relative;
}
.hero_banner {
  position: relative;
  display: flex;
  height: 395px;
  background: url("../images/hero_banner.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
}
.hero_container_inclusive {
  position: absolute;
  left: 20px;
  top: 20px;
}
.hero_container_inclusive img {
  display: block;
  width: 130px;
  height: 130px;
  margin: 0 auto;
}
.hero_container_inclusive div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  background-color: #d22238;
  color: white;
  font-family: nexity-bold;
  font-size: 20px;
  margin: 0 auto;
}
.hero_banner_description {
  align-self: flex-end;
  margin-bottom: -90px;
}
.hero_banner_description div div {
  color: white;
  font-weight: 700;
  padding: vwResp(5) vwResp(5) 0 vwResp(5);
  align-items: center;
  font-weight: 700;
  height: vwResp(80);
  width: vwResp(200);
  font-size: vwResp(28);
  font-family: nexity-bold;
  line-height: vwResp(32);
  background: rgba(240, 186, 97, 1);
}
.hero_banner_description div p {
  padding-left: vwResp(5);
  font-family: nexity-medium;
  color: white;
  font-weight: 500;
  font-size: vwResp(28);
  line-height: vwResp(32);
  
}
.hero_banner_description div:first-child {
  font-weight: 400;
  font-family: nexity-medium;
}
/* .hero_banner_description span {
  color: white;
  padding: 10px;
  display: inline-flex;
  align-items: center;
  font-weight: 700;
  height: 66px;
  font-size: 42px;
  font-family: nexity-bold;
  line-height: 46px;
  background: #48accf;
} */
.description_deskt {
  display: none;
}
.bg_box {
  /* Heroe Banner */
  position: absolute;
  z-index: -1;
  width: 286px;
  height: 200px;
  right: 0px;
  top: 0px;
  background: linear-gradient(140.45deg, #ffba4c 2.88%, #ffe9c2 91.55%);
}
.now-style {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #d22238 !important;
  height: 46px !important;
  margin-left: 5px;
  transform: rotate(-2.01deg);
  width: fit-content;
}
.cta_hero {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin: 20px 0;
  width: 200px;
  height: 50px;
  background: #d22238;
  border-radius: 3px;
  font-family: nexity-bold;
  font-size: 18px;
}

.cta_hero img {
  width: 10px;
  height: 10px;
  margin-left: 13px;
}
/* DESKTOP */

@media screen and (min-width: 1024px) {
  #container_hero_banner {
    padding: 30px 30px 30px 30px;
  }
  .hero_container_inclusive {
    top: 42.75px;
    right: 20px;
    left: initial;
  }
  .hero_container_inclusive img {
    width: 285px;
    height: 227px;
    margin: 0 auto;
  }
  .hero_container_inclusive div {
    transform: rotate(-2.04deg);
    width: 188px;
    height: 34px;
    font-size: 30px;
  }
  .hero_banner {
    height: 386px;
    background: url("../images/hero_banner-deskt.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .bg_box {
    width: 286px;
    height: 314px;
  }
  .description_deskt {
    display: block;
  }
  .hero_banner > img {
    width: 150px;
    height: 150px;
    position: absolute;
    top: 30px;
    right: 30px;
    left: auto;
  }

  .hero_banner_description > span {
    display: none;
  }
  .hero_banner_description {
    flex-direction: column;
    width: auto;
    margin-bottom: 0;
    margin: 0px 125px 80px 50px;
  }
  .hero_banner_description div div {
    color: white;
    font-weight: 700;
    width: initial;
    padding: 10px;
    align-items: center;
    font-weight: 700;
    height: 70px;
    font-size: 42px;
    font-family: nexity-bold;
    line-height: 46px;
    background: rgba(240, 186, 97, 0.75);
  }
  .hero_banner_description div p {
    font-family: nexity-medium;
    color: white;
    font-weight: 500;
    font-size: 42px;
    line-height: 46px;
        margin-bottom: 10px;
  }
  .hero_banner_description div:first-child {
    font-weight: 400;
    font-family: nexity-medium;
  }
  .now-style {
    transform: rotate(-1deg);
    font-weight: 900;
  }
  .bg_box {
    position: absolute;
    width: 878px;
    height: 292px;
    left: 0;
    top: 0;
    background: linear-gradient(140.45deg, #ffba4c 2.88%, #ffe9c2 91.55%);
  }
}
@media screen and (min-width: 1024px) and (max-width: 1180px) {
  .hero_container_inclusive img {
    width: 150px;
    height: 150px;
  }
  .hero_container_inclusive div {
    display: none;
    width: 80px;
    height: 40px;
    font-size: 20px;
  }
}
@media screen and (max-width: 365px) {
  .hero_banner_description span {
    font-size: 34px;
  }
}
