#primo2_invest {
  position: relative;
  margin-top: 20px;
  z-index: 3;
}
.container_primo2_header {
  width: 100%;
  padding: 20px;
  position: relative;
}
.primoinvest2_header {
  position: relative;
  display: flex;
  height: 440px;
  background: url("../images/primoinvest2_img.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
}
.primoinvest2_header_description {
  align-self: flex-end;
  width: 350px;
  margin-bottom: -67px;
}
.primoinvest2_header_description span {
  color: white;
  padding: 10px;
  display: inline-flex;
  align-items: center;
  font-weight: 700;
  height: 66px;
  font-size: 42px;
  font-family: nexity-bold;
  line-height: 46px;
  background: #48accf;
}

.calm-style {
  background: #d22238 !important;
  height: 46px !important;
  transform: rotate(-2.01deg);
  display: inline-block;
  width: fit-content;
}
.primoinvest2_inclusive {
    position: absolute;
    top: 20px;
    left: 20px;
}
.primo2_description {
  width: 90%;
  margin: 20px auto 60px auto;
}

.primo2_description h1 {
  font-family: nexity-bold;
  font-size: 42px;
  line-height: 58.8px;
}
.primo2_description h2 {
  font-family: nexity-medium;
  font-size: 24px;
  line-height: 33.6px;
  margin: 10px 0px;
}
.primo2_description p {
  font-family: nexity-regular;
  font-size: 24px;
  line-height: 33.6px;
}

.cta_primo2 {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin: 20px 0;
  width: 200px;
  height: 50px;
  background: #d22238;
  border-radius: 3px;
  font-family: nexity-bold;
  font-size: 18px;
}
.cta_primo2 img {
  margin-left: 10px;
}
.bginvest_box {
  position: absolute;
  z-index: -1;
  width: 197px;
  height: 244px;
  top: 0;
  right: 0;
  background: linear-gradient(269.31deg, #ffbc52 0%, #ffe9c2 100%);
}
@media screen and (min-width: 1024px) {
  #primo2_invest {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    margin-top: 60px;
    margin-bottom: 60px;
  }
  
  .container_primo2_header {
    height: initial;
    width: auto;
    padding: 0;
  }
  .primoinvest2_header {
    flex-direction: row-reverse;
    width: 616px;
    height: 364px;
    background: url("../images/primoinvest2_img-deskt.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .primoinvest2_inclusive {
    display: block;
    position: absolute;
    top: 20px;
    left: 22px;
  }
  .primoinvest2_inclusive img {
  }
  .primoinvest2_inclusive div {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    height: 40px;
    background-color: #d22238;
    font-family: nexity-bold;
    font-size: 36px;
    margin: 15px auto 0 auto;
  }
  .primoinvest2_header_description {
    margin: 0 0 25px 25px;
  }

  .primo2_description {
    width: 500px;
    margin: 0;
  }
  .primo2_description p {
    margin-top: 35px;
  }
  .bginvest_box {
    position: absolute;
    top: initial;
    bottom: -30px;
    right: -30px;
    width: 474px;
    height: 295px;
    background: linear-gradient(269.31deg, #ffbc52 0%, #ffe9c2 100%);
    transform: rotate(180deg);
  }
}

@media screen and (max-width: 365px) {
  .primoinvest2_header_description span {
    font-size: 30px;
  }
}
